<template>
  <div id="background" :style="{ backgroundImage: 'url(' + imagepath() + ')'  }">
    <Header color="primary"/>

    <div class="wrapper">
      <div id="content">
        <v-container :style="{ backgroundColor: setBackgroundColor() }">
          <v-row align="center" justify="center">
            <v-col class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '80px' }" cols="12">
              Let's get married!
            </v-col>
          </v-row>
          <v-row>
            <v-col>
        <v-card class="card" outlined  color="transparent" v-if="!this.$vuetify.breakpoint.xsOnly">
          <v-list-item style="font-family: Raleway">
            <v-list-item-avatar tile size="300" color="blue">
                      <v-img
                          width="300px"
                          :src="require('../../assets/brautpaar/both.jpg')"
                          :lazy-src="require('../../assets/brautpaar/both.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1 primary--text font-weight-bold" :style="{ fontFamily: 'Raleway', fontSize: getFontSize(true) }"
              >Our Story</v-list-item-title>
              <div :style="{ fontFamily: 'Raleway', whiteSpace: 'pre-line', fontSize: getFontSize() }">
                {{ text }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
              <v-card class="card" outlined color="transparent" v-if="this.$vuetify.breakpoint.xsOnly" align="center">
                <v-img
                    square
                    max-width="300px"
                    :src="require('../../assets/brautpaar/both.jpg')"
                    :lazy-src="require('../../assets/brautpaar/both.jpg')"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title align="center">Our Story</v-card-title>

                <v-card-text class="text--primary">
                  <div style="font-family: Raleway; text-align: justify;">
                    {{ text }}
                  </div>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-timeline >
                <v-timeline-item
                    v-for="(year, i) in years"
                    :key="i"
                    :color="year.color"
                    small
                    fill-dot
                    :style="{ fontFamily: 'Raleway', fontSize: getFontSize() }"
                >
                  <template v-slot:opposite>
        <span
            :class="`font-weight-bold`"
            :style="{ color: year.color, fontSize: getFontSize(true) }"
            v-text="year.year"
        ></span>
                  </template>
                  <div class="py-4">
                    <h2 :class="`font-weight-bold ${year.color}--text`" :style="{ fontFamily: 'Raleway', color: year.color, fontSize: getFontSize(true) }">
                      {{ year.title }} <v-icon :color="year.color">{{ year.icon }}</v-icon>
                    </h2>
                    <div>
                      {{ year.text }}
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="8">
              <v-carousel
                  height="auto"
                  :continuous="true"
                  :cycle="true"
                  hide-delimiter-background
                  show-arrows-on-hover
                  :delimiter-icon= icons.mdiMinus>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                  >
                    <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
                  </v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>{{ icons.mdiChevronRight }}</v-icon>
                  </v-btn>
                </template>
                <v-carousel-item
                    v-for="(item,i) in items"
                    :key="i"
                >
                  <v-img aspect-ratio="1.77" :src="item.src">

                  </v-img>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>

      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import sharedfunctions from "../../sharedfunctions";
import {mdiMinus, mdiRing, mdiChevronLeft, mdiChevronRight, mdiHeart, mdiCheck, mdiAllInclusive, mdiBaby, mdiPartyPopper } from "@mdi/js";

export default {
  name: "Brautpaar",
  components: {Footer, Header},
  data: () => ({
    icons: {
      mdiMinus,
      mdiRing,
      mdiChevronLeft,
      mdiChevronRight
    },
    items: [
      {
        src: require("../../assets/brautpaar/DSC02793_b.jpg")
      },
      {
        src: require("../../assets/brautpaar/DSC02798_b.jpg")
      },
      {
        src: require("../../assets/brautpaar/verlobung_1.jpg")
      },
      {
        src: require("../../assets/brautpaar/verlobung_2.jpg")
      }
    ],
    years: [
      {
        color: 'rgba(81,93.5,82,1)',
        year: '10.11.2020',
        text: "Heute sagen wir uns zum ersten mal \"Ich liebe dich\" unter dem Sternenhimmel in Mompé Medel. ",
        title: 'Ich liebe dich',
        icon: mdiHeart
      },
      {
        color: 'rgba(101,117,102,1)',
        year: '08.08.2022',
        text: 'Wenn wir schon unsere Ferien in Italien absagen mussten, so genossen wir wenigstens ein paar schöne Tage im Ferienhaus in Mompé Medel. Auf einer schönen Wanderung beim Lukmanierpass war es nun endlich so weit: die Frage aller Fragen wurde gestellt!',
        title: "Verlobung",
        icon: mdiRing
      },
      {
        color: 'rgba(116,134,116,1)',
        year: '01.09.2022',
        text: 'Ein kleines Wunder wächst heran. Wenn auch die vielen schlechten Tage nicht einfach sind, so ist die Vorfreude doch sehr gross.',
        title: 'Positiv getestet',
        icon: mdiCheck
      },
      {
        color: 'rgba(135,156,136,1)',
        year: '03.02.2023',
        text: 'Heute wird geheiratet!',
        title: 'Hochzeit',
        icon: mdiAllInclusive
      },
      {
        color: 'rgba(162,187,163,1)',
        year: '17.05.2023',
        text: 'An diesem schönen Frühlingstag soll unser Kleines das Licht der Welt erblicken.',
        title: 'Geburtstermin',
        icon: mdiBaby
      },
      {
        color: 'rgba(180,208,181cd,1)',
        year: '2024',
        text: 'Aufgrund des Babys wollten wir unser Hochzeitsfest nicht im 2023 feiern. Dies würden wir hier gerne nachholen und hoffen, dass viele von euch dabei sein können.',
        title: 'Hochzeitsfest',
        icon: mdiPartyPopper
      },
    ],
    text: "Wir würden euch hier gerne eine romantische Geschichte erzählen, das würde aber leider nicht der Wahrheit entsprechen. Denn kennen gelernt haben wir uns im Jahr 2020 Corona-konform über eine Dating-App. Nach nicht allzu langem hin und her schreiben fand unser erstes Treffen statt und ab da an waren wir schon fast unzertrennlich. Bei uns geht alles ein wenig schneller, was vor allem unsere Familien immer wieder zur Verwunderung brachte. Nachdem zusammenziehen, folgte auch bald schon unser erster Familienzuwachs aus dem Auslandtierschutz, Keona. Ein Jahr später durfte Sabrina mit einer gigantischen Aussicht, die lang ersehnte Fragen aller Fragen mit JA beantworten. Wie bereits erwähnt, halten wir nicht viel vom langen Warten und so machte es sich schon bald unser kleines Würmchen bei Sabrina im Bauch gemütlich. Somit war klar für Sabrina und Silas wird es eine Winterhochzeit geben.",
  }),
  methods: {
    getFontSize(isTitle = false) {
      if (this.isMobile()) {
        return isTitle ? 'medium' : 'small';
      } else {
        return isTitle ? 'x-large' : 'large';
      }
    },
    setBackgroundColor() {
      if (this.isMobile()) {
        return 'rgba(241,243,240,0.4)'
      } else {
        return 'rgba(241,243,240,0.8)'
      }
    },
    imagepath() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require("../../assets/backgrounds/background.jpg");
        case 'sm':
          return require("../../assets/backgrounds/background.jpg");
        case 'md':
          return require("../../assets/backgrounds/background_q.jpg");
        case 'lg':
          return require("../../assets/backgrounds/background_q.jpg");
        case 'xl':
          return require("../../assets/backgrounds/background_q.jpg");
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    initColorScheme() {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    },
    // eslint-disable-next-line no-unused-vars
    myEventHandler(e) {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    }
  },
  beforeMount(){
    this.$vuetify.theme.dark = false;
  },
  created(){
    window.addEventListener("resize", this.myEventHandler);
    this.isMobile = sharedfunctions.isMobile;
    this.updateDarkMode = sharedfunctions.updateDarkMode;
    this.initColorScheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style scoped>

</style>
